#logo {
    height: 80px;
}

.navbar-text {
    margin: 0 1rem;
    color: #ffffff !important;
}

.nav-link {
    font-size: 1.1rem;
    margin: 0 0.025rem;
    font-weight: 600;
}
