#gallery {
    margin: 7rem 1rem;
}

.album {
    margin-top: 2rem;
}

.accordion-header > * {
    background-color: #545050;
    color: inherit;
}

.album-image {
    max-width: 500px;
    width: 100%;
    padding: 1rem;
}