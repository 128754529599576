.border-word {
    border-top: 0.3rem solid #ffffff;
    border-bottom: 0.3rem solid #ffffff;
    padding: 1rem;
    font-size: 3rem;
    max-width: 720px;
}

@media only screen and (max-width: 768px) {
    .container {
        width: 90%;
    }
    
}

@media only screen and (max-width: 500px) {
    .border-word {
        font-size: 2.3rem;
    }
    
}